import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { useSelector } from "react-redux";


const Parcels = () => {
    const country = useSelector((state) => state.user.country);
    const role = useSelector((state) => state.user.role);
    const [keyword, setKeyword] = useState("");
    const [pageChanged, setPageChanged] = useState(1);
    const [showEditModal, setShowEditModal] = useState(false);
    const [flights, setFlights] = useState([]);
    const [total, setTotal] = useState(0);
    const [actionsModal, setActionsModal] = useState(false);
    const [actionType, setActionType] = useState("add");
    const [showViewMode, setShowViewMode] = useState(false);
    const [parcels, setParcels] = useState([]);
    const [convertModal, setConverModal] = useState(false);
    const [formData, setFormData] = useState({
        tracking: "", dangerous: false, phone: "", nameGeo: "", surnameGeo: "", surname: "", receiver: "", description: "", userId: "", flightId: "", price: "", weight: "", country: "", parcelReceiveAddress: "", personalNumber: "", name: "", tracking2: ""
    });
    const [active, setActive] = useState("");
    const [notification, setNotification] = useState({
        show: false,
        header: "",
        body: "",
        button: ""
    });

    const searchByKeyword = (e) => {
        if (e.keyCode === 13) {
            axios(`/api/admin/parcels?page=${pageChanged}&limit=10&keyword=${keyword}&country=${country}`)
                .then((response) => {
                    setTotal(response.data.totalCount)
                    const data = response.data.data;
                    setParcels([...data]);
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }

    const convert = (parcel) => {
        axios.put(`/api/parcel/${parcel.Id}/convert`, {debt: parcel.debt})
            .then((res) => {
                const amount = res.data.amount;
                setNotification({
                    show: true,
                    header: "კონვერტაცია",
                    body: "გზავნილის დავალიანება დაკონვერტირებულია",
                    button: "დახურვა"
                })
                let data = parcels.map((p) => {
                    if(p.Id === parcel.Id){
                        return {...parcel, debt: amount}
                    }else{
                        return {...p}
                    }
                });
                setParcels([...data]);
                setConverModal(false)
            })
            .catch((err) => {
                const message = err.response.data.message.En;
                const description = err.response.data.description;
                setNotification({
                    show: true,
                    header: description,
                    body: message,
                    button: "დახურვა"
                })
            })
    }

    const deleteParcel = (p) => {
        axios.delete(`/api/parcels/${active.Id}?amount=${p.debt}&userId=${p.userId}`)
            .then(() => {
                let data = parcels.filter((p) => {
                    if (p.Id !== active.Id) {
                        return p
                    }
                })

                setParcels([...data]);
                setActionsModal(false)
            })
            .catch((err) => {
                const message = err.response.data.message.En;
                const description = err.response.data.description;
                setNotification({
                    show: true,
                    header: description,
                    body: message,
                    button: "დახურვა"
                })
            })
    }

    const setParcelTaken = () => {
        axios.put(`/api/parcels/${active.Id}/status`)
            .then(() => {
                let data = parcels.map((p) => {
                    if (p.tracking === active.tracking) {
                        return { ...p, status: "გატანილია" }
                    } else {
                        return { ...p }
                    }
                })
                setActionsModal(false);

                setParcels(data);
            })
            .catch((err) => {
                const message = err.response.data.message.En;
                const description = err.response.data.description;
                setNotification({
                    show: true,
                    header: description,
                    body: message,
                    button: "დახურვა"
                })
            })
    }

    const downloadInvoice = () => {
        axios.post(`/api/download/invoice/${active.tracking}`)
            .then((response) => {
                const url = response.data.url;
                window.open(url);
            })
            .catch((err) => {
                const message = err.response.data.message.En;
                const description = err.response.data.description;
                setNotification({
                    show: true,
                    header: description,
                    body: message,
                    button: "დახურვა"
                })
            })
    }

    const addParcelHandler = (e) => {
        e.preventDefault();
        if (actionType === "add") {
            axios.post('/api/parcels', formData)
                .then((res) => {
                    let data = parcels.slice(0, 9);
                    data = [{ ...formData, status: "საწყობში", debt: res.data.data.debt, receiveDate: res.data.data.receiveDate, isPayed: 0, isDeclared: 0 }, ...data];
                    setParcels([...data]);
                    setShowEditModal(false);
                })
                .catch((err) => {
                    const message = err.response.data.message.En;
                    const description = err.response.data.description;
                    setNotification({
                        show: true,
                        header: description,
                        body: message,
                        button: "დახურვა"
                    })
                })
        } else if (actionType === "edit") {
            axios.put(`/api/parcel/${active.Id}`, formData)
                .then((res) => {
                    let data = parcels.map((p) => {
                        if(p.Id === active.Id){
                            return {...p, 
                                flightId: formData.flightId, 
                                nameSurname: formData.nameSurname, 
                                parcelReceiveAddress: formData.parcelReceiveAddress, 
                                receiver: formData.receiver,
                                tracking: formData.tracking,
                                userId: formData.userId,
                                weight: formData.weight
                            }
                        }else{
                            return {...p}
                        }
                    });
                    setShowEditModal(false);
                    setParcels([...data]);
                })
                .catch((err) => {
                    const message = err.response.data.message.En;
                    const description = err.response.data.description;
                    setNotification({
                        show: true,
                        header: description,
                        body: message,
                        button: "დახურვა"
                    })
                })
        }
    }

    const selectUserHander = (e) => {
        if (e.keyCode === 13) {
            axios(`/api/user/${formData.userId}`)
                .then((res) => {
                    const data = res.data.data;
                    setFormData((prev) => {
                        return {
                            ...prev,
                            nameSurname: `${data[0].name} ${data[0].surname}`,
                            parcelReceiveAddress: data[0].officeAddress,
                            receiver: data[0].email,
                            phone: data[0].phone, country: country, nameGeo: data[0].nameGeo, surnameGeo: data[0].surnameGeo, surname: data[0].surname, personalNumber: data[0].personalNumber, name: data[0].name
                        }
                    })
                })
                .catch((err) => {
                    const message = err.response.data.message.En;
                    const description = err.response.data.description;
                    setNotification({
                        show: true,
                        header: description,
                        body: message,
                        button: "დახურვა"
                    })
                })
        }
    }

    useEffect(() => {
        axios(`/api/flights?status=საწყობში&limit=80&page=1&country=${country}&role=${role}`)
            .then((res) => {
                const data = res.data.data;
                setFlights([...data]);
            })
            .catch((err) => {
                const message = err.response.data.message.En;
                const description = err.response.data.description.Geo;
                setNotification({
                    show: true,
                    header: description,
                    body: message,
                    button: "დახურვა"
                })
            })

        axios(`/api/admin/parcels?page=${pageChanged}&limit=10&status=&keyword=${keyword}&country=${country}`)
            .then((res) => {
                const data = res.data.data;
                setTotal(res.data.totalCount);
                setParcels([...data]);
            })
            .catch((err) => {
                const message = err.response.data.message.En;
                const description = err.response.data.description.Geo;
                setNotification({
                    show: true,
                    header: description,
                    body: message,
                    button: "დახურვა"
                })
            })
    }, [pageChanged])

    return (
        <>
            <div className="admin-flights-head">
                <div className="search-keyword-container">
                    <input type="text" onKeyDown={searchByKeyword} placeholder="ჩაწერეთ საძიებო სიტყვა" onChange={(e) => setKeyword(e.target.value)} className="form-control" style={{ maxWidth: "400px" }} />
                </div>
                <button className="pay-button" onClick={() => {
                    setShowEditModal(true);
                    setActionType("add")
                    setFormData({
                        tracking: "",
                        userId: "EP",
                        nameSurname: "",
                        flightId: "",
                        receiver: "",
                        parcelReceiveAddress: "",
                        weight: ""
                    })
                }}>Add new</button>
            </div>
            <Table responsive>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>თრექინგი</th>
                        <th>თარიღი</th>
                        <th>რეისი</th>
                        <th>სტატუსი</th>
                        <th>გადახდილი</th>
                        <th>დეკლარაცია</th>
                        <th>წონა</th>
                        <th>დავალიანება</th>
                        <th>ოფისი</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        parcels.map((p) => {
                            return (
                                <tr key={p.tracking}>
                                    <td>{p.userId}</td>
                                    <td>{p.tracking}</td>
                                    <td>{p.receiveDate}</td>
                                    <td>{p.flightId}</td>
                                    <td>{p.status}</td>
                                    <td>{p.isPayed === 0 ? <img className="table-icon" src="/static/image/cross.png" alt="icon" /> : <img className="table-icon" src="/static/image/check.png" alt="icon" />}</td>
                                    <td>{p.isDeclared === 0 ? <img className="table-icon" src="/static/image/cross.png" alt="icon" /> : <img className="table-icon" src="/static/image/check.png" alt="icon" />}</td>
                                    <td>{p.weight}</td>
                                    <td>{p.debt}</td>
                                    <td>{p.parcelReceiveAddress}</td>
                                    <td>
                                        <button title='Edit Parcel' onClick={() => {
                                            setActive(p);
                                            setActionsModal(true);
                                        }} className="none-button"><img style={{ width: "20px" }} src="/static/image/edit.svg" alt="actions" /></button>
                                        <button onClick={() => {
                                            setConverModal(true)
                                            setActive(p);
                                        }}>
                                            c
                                        </button>
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </Table>

            <div className="pagination-container">
                {pageChanged > 1 && <span className="next-page-button" onClick={() => {
                    setPageChanged((prev) => (prev - 1))
                }}>
                    {'<<'}
                </span>}
                {
                    Array.from(Array(Math.ceil(total / 10)).keys())
                        .map((p, k) => {
                            if (p < pageChanged - 2 || p + 1 > pageChanged + 2) {
                                return
                            } else {
                                return (<span key={k * 15} className={(pageChanged === p + 1) ? "active-page-number" : undefined} onClick={() => {
                                    setPageChanged(p + 1)
                                }
                                }>
                                    {p + 1}
                                </span>)
                            }
                        })
                }
                {pageChanged < Math.ceil(total / 10) && <span className="next-page-button" onClick={() => {
                    setPageChanged((prev) => (prev + 1))
                }}>
                    {'>>'}
                </span>}
            </div>

            <Modal show={convertModal} size="md" area-labelledby="contained-modal-title-vcenter" centered onHide={() => setConverModal(false)}>
                <Modal.Header>
                    <h3 style={{color: "white"}}>ნამდვილად გსურთ დავალიანების კონვერტაცია?</h3>
                </Modal.Header>
                <Modal.Footer>
                    <Button variant="info" onClick={() => setConverModal(false)}>არა</Button>
                    <Button variant="danger" onClick={() => convert(active)}>დიახ</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={actionsModal} size="md" area-labelledby="contained-modal-title-vcenter" centered onHide={() => setActionsModal(false)}>
                <Modal.Body>
                    <div className="actions-modal-block">
                        <Button variant="primary" onClick={() => {
                            setShowViewMode(true);
                            setActionsModal(false);
                        }}>ნახვა</Button>
                        <Button variant="primary" onClick={() => {
                            setShowEditModal(true);
                            setActionsModal(false);
                            setActionType("edit");
                            setFormData({
                                tracking: active.tracking,
                                userId: active.userId,
                                nameSurname: `${active.nameEn} ${active.surnameEn}`,
                                flightId: active.flightId,
                                receiver: active.receiver,
                                parcelReceiveAddress: active.parcelReceiveAddress,
                                weight: active.weight,
                                country: active.country
                            })
                        }}>რედაქტირება</Button>
                        <Button variant="primary" onClick={downloadInvoice}>ინვოისი</Button>
                    </div>
                    <div className="actions-modal-block">
                        <Button variant="danger" onClick={setParcelTaken}>გატანილია</Button>
                        <Button variant="danger" onClick={() => deleteParcel(active)}>წაშლა</Button>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={showViewMode} size="lg" area-labelledby="contained-modal-title-vcenter" centered onHide={() => setShowViewMode(false)}>
                <Modal.Header>
                    <h3 style={{ color: "white" }}>მომხმარებლის მონაცემები</h3>
                </Modal.Header>
                <Modal.Body>
                    <div className="container" style={{ paddingTop: "0px" }}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="view-users-section-item">
                                    <p className="view-mode-option">თრექინგი:</p>
                                    <p className="view-mode-value">{active.tracking}</p>
                                </div>
                                <div className="view-users-section-item">
                                    <p className="view-mode-option">ვალუტა:</p>
                                    <p className="view-mode-value">{active.currency}</p>
                                </div>
                                <div className="view-users-section-item">
                                    <p className="view-mode-option">მიმღები:</p>
                                    <p className="view-mode-value">{active.userId}</p>
                                </div>
                                <div className="view-users-section-item">
                                    <p className="view-mode-option">ვებსაიტი:</p>
                                    <p className="view-mode-value">{active.website}</p>
                                </div>
                                <div className="view-users-section-item">
                                    <p className="view-mode-option">სახიფათო:</p>
                                    <p className="view-mode-value">{active.dangerous === 0 ? "არა" : "დიახ"}</p>
                                </div>
                                <div className="view-users-section-item">
                                    <p className="view-mode-option">მიღების მისამართი:</p>
                                    <p className="view-mode-value">{active.officeAddress}</p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="view-users-section-item">
                                    <p className="view-mode-option">რეისი:</p>
                                    <p className="view-mode-value">{active.flightId}</p>
                                </div>
                                <div className="view-users-section-item">
                                    <p className="view-mode-option">გადახდილია:</p>
                                    <p className="view-mode-value">{active.isPayed}</p>
                                </div>
                                <div className="view-users-section-item">
                                    <p className="view-mode-option">ფასი:</p>
                                    <p className="view-mode-value">{active.price}</p>
                                </div>
                                <div className="view-users-section-item">
                                    <p className="view-mode-option">სტატუსი:</p>
                                    <p className="view-mode-value">{active.status}</p>
                                </div>
                                <div className="view-users-section-item">
                                    <p className="view-mode-option">ქვეყანა:</p>
                                    <p className="view-mode-value">{active.country}</p>
                                </div>
                                <div className="view-users-section-item">
                                    <p className="view-mode-option">აღწერა:</p>
                                    <p className="view-mode-value">{active.description}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => setShowViewMode(false)}>დახურვა</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showEditModal} size="lg" area-labelledby="contained-modal-title-vcenter" centered onHide={() => setShowEditModal(false)}>
                <Modal.Header>
                    <h3 style={{ color: "white" }}>გზავნილის დამატება</h3>
                </Modal.Header>
                <Modal.Body>
                    <form className="container" style={{ paddingTop: "0px" }} onSubmit={addParcelHandler}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="mt-3 mb-1 color-white">თრექინგი</label>
                                    <input type="text" className="form-control" value={formData.tracking} onChange={(e) => setFormData((prev) => ({ ...prev, tracking: e.target.value }))} required />
                                </div>
                                <div className="form-group">
                                    <label className="mt-3 mb-1 color-white">მომხმარებლის ID</label>
                                    <input type="text" className="form-control" value={formData.userId} onKeyDown={selectUserHander} onChange={(e) => setFormData((prev) => ({ ...prev, userId: e.target.value }))} required />
                                </div>
                                <div className="form-group">
                                    <label className="mt-3 mb-1 color-white">სახელი & გვარი</label>
                                    <input type="text" className="form-control" value={formData.nameSurname} onChange={(e) => setFormData((prev) => ({ ...prev, nameSurname: e.target.value }))} required />
                                </div>
                                <div className="form-group">
                                    <label className="mt-3 mb-1 color-white">რეისი</label>
                                    <select className="form-control" value={formData.flightId} onChange={(e) => setFormData((prev) => ({ ...prev, flightId: e.target.value }))} required >
                                        <option>რეისი</option>
                                        {
                                            flights.map((f) => {
                                                return <option key={f.flightId} value={f.flightId}>
                                                    {f.flightId}
                                                </option>
                                            })
                                        }
                                    </select>
                                </div>
                                {role === "sadmin" && <div className="form-group">
                                    <label className="mt-3 mb-1 color-white">ქვეყანა</label>
                                    <select className="form-control" value={formData.country}
                                            onChange={(e) => setFormData((prev) => ({
                                                ...prev,
                                                country: e.target.value
                                            }))} required>
                                        <option>მონიშნეთ ქვეყანა</option>
                                        <option value={"poland"}>Poland</option>
                                        <option value={"usa"}>USA</option>
                                        <option value={"greece"}>Greece</option>
                                        <option value={"china"}>China (Air)</option>
                                        <option value={"chinaLand"}>China (Land)</option>
                                        <option value={"turkey"}>Turkey</option>
                                    </select>
                                </div>}
                                {country === "china" && <div className="form-group">
                                    <label className="mt-3 mb-1 color-white">ქვეყანა</label>
                                    <select className="form-control" value={formData.country}
                                            onChange={(e) => setFormData((prev) => ({
                                                ...prev,
                                                country: e.target.value
                                            }))} required>
                                        <option>მონიშნეთ ქვეყანა</option>
                                        <option value={"china"}>China (Air)</option>
                                        <option value={"chinaLand"}>China (Land)</option>
                                    </select>
                                </div>}
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="mt-3 mb-1 color-white">თრექინგი 2</label>
                                    <input type="text" className="form-control" value={formData.tracking2} onChange={(e) => setFormData((prev) => ({ ...prev, tracking2: e.target.value }))} />
                                </div>
                                <div className="form-group">
                                    <label className="mt-3 mb-1 color-white">მიღების მისამართი</label>
                                    <input type="text" className="form-control" value={formData.parcelReceiveAddress} onChange={(e) => setFormData((prev) => ({ ...prev, parcelReceiveAddress: e.target.value }))} required />
                                </div>
                                <div className="form-group">
                                    <label className="mt-3 mb-1 color-white">მიღები</label>
                                    <input type="text" className="form-control" value={formData.receiver} onChange={(e) => setFormData((prev) => ({ ...prev, receiver: e.target.value }))} required />
                                </div>
                                <div className="form-group">
                                    <label className="mt-3 mb-1 color-white">წონა</label>
                                    <input type="text" className="form-control" value={formData.weight} onChange={(e) => setFormData((prev) => ({ ...prev, weight: e.target.value }))} required />
                                </div>
                            </div>
                            <div className="col-md-12 submit-form-buttons">
                                <Button variant="primary" onClick={() => setShowEditModal(false)}>დახურვა</Button>
                                <Button variant="danger" type="submit" style={{ marginLeft: "20px" }}>{actionType === "add" ? "დამატება" : "შენახვა"}</Button>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

            <Modal show={notification.show} size="md" area-labelledby="contained-modal-title-vcenter" onHide={() => setNotification((prev) => ({ ...prev, show: false }))}>
                <Modal.Header>
                    <h3 style={{ color: "white" }}>{notification.header}</h3>
                </Modal.Header>
                <Modal.Body>
                    <p style={{ color: "white" }}>{notification.body}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => setNotification((prev) => ({ ...prev, show: false }))}>{notification.button}</Button>
                </Modal.Footer>
            </Modal>

        </>
    )
}

export default Parcels