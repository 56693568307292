import './Banner.css'

const Banner = () => {
    return(
        <div className='banner-container'>
            <div className='countries-and-prices'>
                <p>თურქეთი: 2$</p>
                <p>ჩინეთი(საჰაერო - რეალური წონით): 11.5$</p>
                <p>ჩინეთი(სახმელეთო): 5.5$</p>
                <p>საბერძნეთი: 3Є</p>
            </div>
            <img src='/static/image/banner-icon.png' />
        </div>
    )
}

export default Banner