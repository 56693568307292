import { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { Table } from "react-bootstrap";
import languages from "../data/languages";

const Flights = ({ setIsAdmin, setPageName }) => {
    const lang = useSelector((state) => state.language.language);
    const [flights, setFlights] = useState([]);
    const [total, setTotal] = useState(0);
    const [pageChanged, setPageChanged] = useState(1);
    const [keyword, setKeyword] = useState("");

    useEffect(() => {
        setPageName("")
        axios(`/api/flights?page=${pageChanged}&limit=10&keyword=${keyword}`)
            .then((response) => {
                setTotal(response.data.totalCount)
                const data = response.data.data;
                setFlights([...data]);
            })
            .catch((err) => {
                console.log(err)
            })
    }, [pageChanged]);

    useEffect(() => {
        setIsAdmin(false)
    }, [setIsAdmin])

    const searchByKeyword = (e) => {
        if (e.keyCode === 13) {
            axios(`/api/flights?page=${pageChanged}&limit=10&keyword=${keyword}`)
                .then((response) => {
                    setTotal(response.data.totalCount)
                    const data = response.data.data;
                    setFlights([...data]);
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }

    return (
        // <DataTable data={flights} loading={load} pageNow={pageChanged} pageChanged={setPageChanged} totalCount={total} header={flightsHeader} actions={false} dataSetter={setFlights} hiddenCol={["Id"]} />
        <>
            <div className="search-keyword-container">
                <input type="text" onKeyDown={searchByKeyword} placeholder={languages[lang].titles.myPage.keyword} onChange={(e) => setKeyword(e.target.value)} className="form-control" style={{ maxWidth: "400px", marginBottom: "25px" }} />
            </div>
            <Table responsive>
                <thead>
                    <tr>
                        <th>{languages[lang].flightsHead.status}</th>
                        <th>{languages[lang].flightsHead.number}</th>
                        <th>{languages[lang].flightsHead.takeOff}</th>
                        <th>{languages[lang].flightsHead.estimatedArrivalDate}</th>
                        <th>{languages[lang].flightsHead.comments}</th>
                        <th>{languages[lang].flightsHead.country}</th>
                    </tr>
                </thead>
                <tbody>
                    {flights.map((item) => (
                        <tr key={item.flightId}>
                            <td>{item.status}</td>
                            <td>{item.flightId}</td>
                            <td>{item.estimatedArrivalDate}</td>
                            <td>{item.takeOffDate}</td>
                            <td>{item.comments}</td>
                            <td>{item.country}</td>
                        </tr>
                    ))}
                    {
                        flights.length === 0 && (
                            <tr className="blank_row">
                                <td colSpan="6" style={{ textAlign: "center" }}>No items found</td>
                            </tr>
                        )
                    }
                </tbody>
            </Table>
            <div className="pagination-container">
                {pageChanged > 1 && <span className="next-page-button" onClick={() => {
                    setPageChanged((prev) => (prev - 1))
                }}>
                    {'<<'}
                </span>}
                {
                    Array.from(Array(Math.ceil(total / 10)).keys())
                        .map((p, k) => {
                            if (p < pageChanged - 2 || p + 1 > pageChanged + 2) {
                                return
                            } else {
                                return (<span key={k * 15} className={(pageChanged === p + 1) ? "active-page-number" : undefined} onClick={() => {
                                    setPageChanged(p + 1)
                                }
                                }>
                                    {p + 1}
                                </span>)
                            }
                        })
                }
                {pageChanged < Math.ceil(total / 10) && <span className="next-page-button" onClick={() => {
                    setPageChanged((prev) => (prev + 1))
                }}>
                    {'>>'}
                </span>}
            </div>
        </>
    )
}

export default Flights
