import React, { useEffect, useState } from "react";
import "./App.css";
import "./reset.css"
import { Switch } from "react-router-dom";
import Header from "./Common/Header/Header";
import Sidebar from "./Common/Sidebar/Sidebar";
import { Route } from "react-router-dom/cjs/react-router-dom.min";
import Main from "./pages/Main";
import Footer from "./Common/Footer/Footer";
import Flights from "./pages/Flights";
import MyPage from "./pages/MyPage";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { userAction } from "./store/user";
import Services from "./Components/Services/Services";
import UsefullInformation from "./Components/UsefullInformation/UsefullInformation";
import AdminRoute from "./Admin/AdminRoute";
import MessengerCustomerChat from 'react-messenger-customer-chat';
import TermsAndConditions from "./Components/TermsAndConditions";
import Markets from "./pages/Markets";

function App() {
    const dispatch = useDispatch();
    const [admin, setAdmin] = useState(false);
    const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
    const role = useSelector((state) => state.user.role);
    const [pageName, setPageName] = useState("");
    const [showSidebar, setShowSidebar] = useState(true);
    const [showSidebarMobile, setShowSidebarMobile] = useState(true);
    useEffect(() => {
        axios(`/api/user`, {
            withCredentials: true,
        })
            .then((res) => {
                const data = res.data.data;
                dispatch(userAction.setIsLoggedIn(true));
                dispatch(userAction.setUserData({
                    name: data.name,
                    surname: data.surname,
                    initials: `${data.name.slice(0, 1)}${data.surname.slice(0, 1)}`,
                    userId: data.userId,
                    role: data.role,
                    phone: data.phone,
                    address: data.address,
                    email: data.email,
                    officeAddress: data.officeAddress,
                    country: data.country
                }))
            })
            .catch((err) => {
                console.log(err);
                dispatch(userAction.setIsLoggedIn(false));
            })
    }, [dispatch])

    return (
        <React.Fragment>
            <MessengerCustomerChat
                pageId="223283760867253"
                appId="343509945244814"
            />
            <Header pageName={pageName} setShowSidebar={setShowSidebarMobile} auth={isLoggedIn} page={pageName} isAdmin={admin} />
            <div className="pages-container">
                <div className="sidebar-box">
                    {showSidebar && <Sidebar showSidebar={showSidebarMobile} setShowSidebar={setShowSidebarMobile} auth={isLoggedIn} isAdmin={admin} />}
                </div>
                <div className={`pager ${admin ? 'full-page-view' : ''}`}>
                    <Switch>
                        <Route path='/' exact><Main setPageName={setPageName} setIsAdmin={setAdmin} /></Route>
                        <Route path='/flights' exact><Flights setPageName={setPageName} setIsAdmin={setAdmin} /></Route>
                        {isLoggedIn !== false && <Route path='/my-page' exact><MyPage setPageName={setPageName} setIsAdmin={setAdmin} /></Route>}
                        <Route path='/services'><Services setPageName={setPageName} setIsAdmin={setAdmin} /></Route>
                        <Route path='/shopping'><Markets setPageName={setPageName} setIsAdmin={setAdmin} /></Route>
                        <Route path='/useful-information'><UsefullInformation setPageName={setPageName} setIsAdmin={setAdmin} /></Route>
                        <Route path='/terms-and-conditions'><TermsAndConditions setPageName={setPageName} /></Route>
                        {(role === "admin" || role === "sadmin") && <Route path='/admin'>
                            <AdminRoute setIsAdmin={setAdmin} showSidebar={setShowSidebar} />
                        </Route>}
                        <Route path='/*'><div className="page-404">
                            <h2 style={{ color: "black" }}>404 Page not found</h2>
                        </div></Route>
                    </Switch>
                </div>
            </div>
            {!admin && <Footer/>}
        </React.Fragment>
    );
}

export default App;
