import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import './Header.css';
import { Button, Modal } from 'react-bootstrap'
import { useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from "react-redux";
import UserDropdown from './UserDropdown';
import '../DataTable/DataTable.css';
import { languagesAction } from '../../store/language';
import languages from '../../data/languages';
import OutsideClickHandler from 'react-outside-click-handler';

const Header = ({auth, isAdmin, pageName, setShowSidebar}) => {
    const [showAuth, setShowAuth] = useState(false);
    const [showSignUp, setShowSignUp] = useState(false);
    const lang = useSelector((state) => state.language.language);
    const [showAsideModal, setShowAsideModal] = useState(false);

    const users = useSelector((state) => state.user);
    const [showDropdown, setShowDropdown] = useState(false);
    const dispatch = useDispatch();
    const [notification, setNotification] = useState({
        show: false,
        header: "",
        body: "",
        button: ""
    });



    return (
        <>
            <header className="header-container" style={{background: pageName === "home" ? "none" : ""}}>
                <div className='header-content'>
                    <img src='https://usdelivery.ge/static/image/bar.svg' className='show-menu-bar' onClick={() => setShowSidebar(true)} />
                    <ul className='sidebar-content-list'>
                        {!isAdmin && (<>
                            <li className='sidebar-item'><Link to="/">{languages[lang].navigation.main}</Link></li>
                            {auth && <li className='sidebar-item'><Link to="/my-page">{languages[lang].navigation.myPage}</Link></li>}
                            <li className='sidebar-item'><Link to="/flights">{languages[lang].navigation.flights}</Link></li>
                            <li className='sidebar-item'><Link to="/shopping">{languages[lang].navigation.shopping}</Link></li>
                            <li className='sidebar-item'><Link to="/services/online-parcels">{languages[lang].navigation.services}</Link></li>
                            <li className='sidebar-item'><Link to="/useful-information/how-to-buy">{languages[lang].navigation.info}</Link></li>
                            <li className='sidebar-item'><Link to="/contact">{languages[lang].navigation.contact}</Link></li>
                        </>)}
                        {
                            isAdmin && (
                                <>
                                    <li className='sidebar-item'><Link
                                        to="/admin/dashboard">{languages[lang].navigation.dashboard}</Link></li>
                                    <li className='sidebar-item'><Link
                                        to="/admin/markets">მაღაზია</Link></li>
                                    <li className='sidebar-item'><Link
                                        to="/admin/delivery">{languages[lang].navigation.delivery}</Link></li>
                                    <li className='sidebar-item'><Link
                                        to="/admin/flights">{languages[lang].navigation.flights}</Link></li>
                                    <li className='sidebar-item'><Link
                                        to="/admin/users">{languages[lang].navigation.users}</Link></li>
                                    <li className='sidebar-item'><Link
                                        to="/admin/parcels">{languages[lang].navigation.parcels}</Link></li>
                                </>
                            )
                        }
                    </ul>
                </div>
            </header>
            <Modal show={showAsideModal} onHide={() => setShowAsideModal(false)} dialogClassName="aside-modal">
                <Modal.Header>
                    {users.isLoggedIn === true ? (
                        <>
                            <button style={{ display: "block" }} className='header-user-dropdown-icon' onClick={() => setShowDropdown(!showDropdown)}>
                                {users.initials}
                            </button>
                        </>
                    ) : (
                        <>
                            <button onClick={() => {
                                setShowAuth(true);
                                setShowAsideModal(false);
                            }} className='header-sign-in-button'>ავტორიზაცია</button>
                            <button className='header-sign-up-button' onClick={() => {
                                setShowSignUp(true);
                                setShowAsideModal(false);
                            }}>რეგისტრაცია</button>
                        </>
                    )}
                </Modal.Header>
                <Modal.Body>
                    <ul className='sidebar-content-list'>
                        <li className='sidebar-item' onClick={() => setShowAsideModal(false)}><Link to="/">{languages[lang].navigation.main}</Link></li>
                        {users.isLoggedIn && <li onClick={() => setShowAsideModal(false)} className='sidebar-item'><Link to="/my-page">{languages[lang].navigation.myPage}</Link></li>}
                        <li className='sidebar-item' onClick={() => setShowAsideModal(false)}><Link to="/flights">{languages[lang].navigation.flights}</Link></li>
                        <li className='sidebar-item' onClick={() => setShowAsideModal(false)}><Link to="/shopping">{languages[lang].navigation.shopping}</Link></li>
                        <li className='sidebar-item' onClick={() => setShowAsideModal(false)}><Link to="/services/prices">{languages[lang].navigation.price}</Link></li>
                        <li className='sidebar-item' onClick={() => setShowAsideModal(false)}><Link to="/services/online-parcels">{languages[lang].navigation.services}</Link></li>
                        <li className='sidebar-item' onClick={() => setShowAsideModal(false)}><Link to="/useful-information/how-to-buy">{languages[lang].navigation.info}</Link></li>
                        <li className='sidebar-item' onClick={() => setShowAsideModal(false)}><Link to="/contact">{languages[lang].navigation.contact}</Link></li>
                    </ul>
                </Modal.Body>
            </Modal>
            <Modal show={notification.show} size="md" area-labelledby="contained-modal-title-vcenter" onHide={() => setNotification((prev) => ({ ...prev, show: false }))}>
                <Modal.Header>
                    <h3 style={{ color: "white" }}>{notification.header}</h3>
                </Modal.Header>
                <Modal.Body>
                    <p style={{ color: "white" }}>{notification.body}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => setNotification((prev) => ({ ...prev, show: false }))}>{notification.button}</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Header