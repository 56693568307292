import styles from './Footer.module.css'
import {Link} from "react-router-dom";
import React from "react";

const Footer = () => {
    return (
        <footer className={styles['footer-container']}>
            <Link to="/" className={styles['footer-logo']}><img src={`/static/image/logo.png`} className={styles['logo']} alt="logo" /></Link>
            <div className={styles['hr']}></div>
            <div className={styles['social-pages']}>
                <a href="https://www.facebook.com/profile.php?id=61555749434953" style={{textDecoration: "none"}} target="_blank" rel="noreferrer" className={styles["soc-item"]}>
                    <svg
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="white"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M13.6128 11.0992V13.3024H12V15.9952H13.6128V24H16.928V15.9952H19.152C19.152 15.9952 19.3616 14.704 19.4624 13.2912H16.9408V11.4512C16.9408 11.1744 17.3024 10.8048 17.6608 10.8048H19.4656V8H17.0096C13.5312 8 13.6128 10.696 13.6128 11.0992Z"
                            fill="white"
                        />
                        <path
                            d="M6.4 3.2C5.55131 3.2 4.73737 3.53714 4.13726 4.13726C3.53714 4.73737 3.2 5.55131 3.2 6.4V25.6C3.2 26.4487 3.53714 27.2626 4.13726 27.8627C4.73737 28.4629 5.55131 28.8 6.4 28.8H25.6C26.4487 28.8 27.2626 28.4629 27.8627 27.8627C28.4629 27.2626 28.8 26.4487 28.8 25.6V6.4C28.8 5.55131 28.4629 4.73737 27.8627 4.13726C27.2626 3.53714 26.4487 3.2 25.6 3.2H6.4ZM6.4 0H25.6C27.2974 0 28.9252 0.674284 30.1255 1.87452C31.3257 3.07475 32 4.70261 32 6.4V25.6C32 27.2974 31.3257 28.9252 30.1255 30.1255C28.9252 31.3257 27.2974 32 25.6 32H6.4C4.70261 32 3.07475 31.3257 1.87452 30.1255C0.674284 28.9252 0 27.2974 0 25.6V6.4C0 4.70261 0.674284 3.07475 1.87452 1.87452C3.07475 0.674284 4.70261 0 6.4 0Z"
                            fill="white"
                        />
                    </svg>
                    <div className={styles['soc-item-value']}>
                        <p className={styles['soc-item-title']} style={{textDecoration: "none"}}>Facebook</p>
                        <p className={styles['soc-item-name']} style={{textDecoration: "none"}}>Easy Post</p>
                    </div>
                </a>
                <a className={styles["soc-item"]} href="https://maps.app.goo.gl/vW1uJCKwGhr9n9hf9" style={{textDecoration: "none"}} target="_blank" rel="noreferrer">
                    <svg width="26" height="33" viewBox="0 0 26 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M13 0C9.55344 0.00405484 6.2492 1.37123 3.81211 3.80163C1.37502 6.23203 0.00407984 9.52719 1.38307e-05 12.9643C-0.00411413 15.7731 0.915899 18.5057 2.61892 20.7429C2.61892 20.7429 2.97347 21.2084 3.03137 21.2756L13 33L22.9734 21.2697C23.0254 21.2072 23.3811 20.7429 23.3811 20.7429L23.3823 20.7393C25.0844 18.5031 26.004 15.7718 26 12.9643C25.9959 9.52719 24.625 6.23203 22.1879 3.80163C19.7508 1.37123 16.4466 0.00405484 13 0ZM13 17.6786C12.065 17.6786 11.1511 17.4021 10.3737 16.8841C9.59628 16.3661 8.99037 15.6298 8.63257 14.7684C8.27478 13.9069 8.18116 12.9591 8.36357 12.0446C8.54597 11.1301 8.9962 10.2901 9.65732 9.63078C10.3184 8.97148 11.1608 8.52248 12.0778 8.34058C12.9948 8.15868 13.9453 8.25204 14.809 8.60885C15.6728 8.96567 16.4111 9.56991 16.9306 10.3452C17.45 11.1204 17.7273 12.0319 17.7273 12.9643C17.7257 14.2141 17.2272 15.4123 16.341 16.2961C15.4548 17.1798 14.2533 17.677 13 17.6786Z"
                            fill="white"/>
                    </svg>

                    <div className={styles['soc-item-value']}>
                        <p className={styles['soc-item-title']}>მისამართი</p>
                        <p className={styles['soc-item-name']}>სავაჭრო ცენტრი ჩემპიონი, ა. გობრონიძის 2</p>
                    </div>
                </a>
                <a className={styles["soc-item"]} href="mailto:info@easypost.ge" style={{textDecoration: "none"}} >
                    <svg width="26" height="20" viewBox="0 0 26 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M24.8137 0.00871444C24.7029 -0.00290481 24.5913 -0.00290481 24.4806 0.00871444H1.73055C1.58474 0.011002 1.4399 0.0332622 1.29993 0.0748959L13.0406 11.9793L24.8137 0.00871444Z"
                            fill="white"/>
                        <path
                            d="M25.9025 1.29541L14.0725 13.2908C13.768 13.599 13.3562 13.7719 12.9269 13.7719C12.4976 13.7719 12.0857 13.599 11.7812 13.2908L0.056875 1.38641C0.020832 1.52129 0.00172143 1.66027 0 1.80004V18.3454C0 18.7842 0.171205 19.2051 0.475951 19.5153C0.780698 19.8256 1.19402 20 1.625 20H24.375C24.806 20 25.2193 19.8256 25.524 19.5153C25.8288 19.2051 26 18.7842 26 18.3454V1.80004C25.9935 1.62769 25.9606 1.45742 25.9025 1.29541ZM2.73813 18.3454H1.60875V17.1624L7.51562 11.1978L8.66125 12.3643L2.73813 18.3454ZM24.3587 18.3454H23.2213L17.2981 12.3643L18.4437 11.1978L24.3506 17.1624L24.3587 18.3454Z"
                            fill="white"/>
                    </svg>

                    <div className={styles['soc-item-value']}>
                        <p className={styles['soc-item-title']}>Email</p>
                        <p className={styles['soc-item-name']}>info@easypost.ge</p>
                    </div>
                </a>
            </div>
            <div style={{display: "flex", justifyContent: "center"}}>
                <p>ტელ: </p>
                <a href='tel:0322051939' style={{color: "black", marginLeft: "8px", fontWeight: "bold"}}>0322-05-19-39</a>
            </div>
        </footer>
    )
}

export default Footer