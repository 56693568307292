const languages = {
    En: {
        delivery: {
            parcels: "Parcels",
            address: "Address",
            price: "Price",
            date: "Date",
            status: "Status",
            title: "Delivery service"
        },
        mainPage: {
            services_title: "Our services",
            online_parcels: "Online parcels",
            help: "How to buy",
            delivery: "Delivery service",
            bonus: "Bonus points",
            flights: "Flights",
            id: "Flight ID",
            status: "status",
            country: "Country",
            take_off: "Take off",
            arrive: "Arrive",
            on_the_way: "Way",
            arrived: "Arrived",
            office: "Our office",
            calculator: "Calculator"
        },
        parcelsHead: {
            tracking: "Tracking",
            price: "Price",
            status: "Status",
            isPayed: "Is payed",
            currency: "Currency",
            isDeclared: "Is declared",
            debt: "Debt"
        },
        flightsHead: {
            number: "Flight ID",
            estimatedArrivalDate: "Arrival date",
            takeOff: "Take off date",
            status: "Status",
            comments: "Comments",
            country: "Country"
        },
        navigation: {
            main: 'Main',
            myPage: "My Page",
            flights: 'Flights',
            price: "Prices",
            shopping: 'Shopping',
            contact: 'Contact',
            offices: 'Offices',
            info: "information",
            services: "Services",
            dashboard: "Dashboard",
            delivery: "Delivery",
            users: "Users",
            parcels: "Parcels"
        },
        headerButtons: {
            registration: 'Sign Up',
            auth: 'Log In'
        },
        calculator: {
            title: "Calculator",
            width: "Width (sm)",
            height: "Height (sm)",
            length: "length (sm)",
            weight: 'KG',
            calc: "Calculate",
            description: [
                "For China only", "Shipping charges from China are calculated by both physical and volumetric weight",'Recomandation',
                "From China, ask the seller to pack the undamaged items without a box, so that they are not counted by volume.", "If the volumetric weight is greater than the physical weight, it will be calculated by volumetric weight. Use the calculator to calculate the fare in advance.",
                "keep in mind"
            ]
        },
        registration: {
            terms: ["Agree", "terms and conditions"],
            title: 'Registration',
            name: "Name",
            identification: "Identification code",
            physicalPerson: "Physical person",
            alreadyRegistered: ["Already have an account?", "Sign In"],
            legalEntity: "legal entity",
            nameGeo: "Name (Geo)",
            surnameGeo: "Surname (Geo)",
            surname: "Surname",
            email: "Email address",
            personal: 'Personal Number',
            phone: 'Phone',
            add: 'Registration',
            dateOfBirth: "Date of birth",
            address: "Address",
            officeAddress: "Office address",
            currentAddress: "Current address",
            password: "Password",
            repeatPass: "Repeat password",
            city: "City",
            save: "Save",
            edit: "Edit profile"
        },
        auth: {
            resetPassword: "Reset password",
            title: "Log In",
            email: "Email Address",
            code: "Enter code",
            resetPassTitle: "Reset password",
            passwordChanged: "Password has been reset",
            newPassword: "Enter new password",
            repeatPassword: "Repeat new password",
            password: "Password",
            submit: "Log In",
            incorrectEmail: 'Email address is not found',
            incorrectPassword: 'password address is not found',
            registration: "Registration",
            resetPass: "Reset password",
            roomNumber: "Room number",
            balance: "Balance",
            debt: "Debt",
            deliveryDebt: "Delivery debt",
            editProfile: "Edit profile",
            logout: "Logout"
        },
        cities: {
            usa: "USA",
            poland: "POLAND",
            greece: "GREECE",
            turkey: "TURKEY",
            china: "CHINA"
        },
        addresses: {
            name: "First Name",
            surname: "Last Name",
            addressOne: "Address 1",
            addressTitle: "Adres",
            addressTitle2: "Adres Başlığı",
            addressTwo: "Address 2",
            address: "Address",
            receiveAddress: "Parcel receive address",
            weight: "Weight",
            city: "City",
            street: "Street",
            streetTur: "Mahalle",
            cityTur: "IL",
            nameTur: "AD",
            surnameTur: "SOYAD",
            flatNumber: "Flat number",
            district: "İlçe",
            state: "State/Province",
            country: "Country",
            postCode: "Postal Code",
            phone: "Phone Number",
            region: "Region"
        },
        services: {
            head: "Services",
            subtitle: "We offer a number of services to improve your life",
            service: [
                {
                    title: "Online parcels",
                    description: "Send the item you purchased to the warehouse in the country you want it from"
                },
                {
                    title: "Help with subscription",
                    description: "Send the item you purchased to the warehouse in the country you want it from"
                },
                {
                    title: "Delivery services",
                    description: "Send the item you purchased to the warehouse in the country you want it from"
                },
                {
                    title: "Bonus points",
                    description: "Send the item you purchased to the warehouse in the country you want it from"
                }
            ]
        },
        servicesDropdown: {
            onlineParcels: "Online parcels",
            help: "Support for Shopping",
            insurance: "Insurance",
            delivery: "Delivery service",
            bonus: "Bonus points",
            prices: "Prices"
        },
        info: {
            howToFollow: "How to buy online",
            terms: "Customs Procedures",
            restrictedItems: "Prohibited Items",
            frequentlyQuestions: "FAQ",
            transportation: "Shipping times",
            aboutUs: "About Us",
            news: "News",
            termss: "Terms and conditions"
        },
        contacts: {
            title: ["Contact us, we will answer", "you as soon as possible!"],
            name: "Name",
            surname: "Surname",
            phone: "Phone",
            message: "Messages",
            contactName: "Contacts",
            location: "Location",
            mail: "Email",
            address: "Mikheil Asatiani st 10b",
            social: "Social networks",
            send: "Send"
        },
        authed: {
            balance: "Balance",
            debt: "Debt",
            edit: "Edit profile",
            bonus: "Bonus points",
            logout: "Log out"
        },
        addParcels: {
            receiver: "Receiver",
            flightId: "Flight ID",
            delivery: "Delivery",
            status: "Status",
            addFile: "Add file",
            userId: "User ID",
            title: "Add parcels",
            tracking: "Tracking",
            price: "Price",
            currency: "Currencies",
            weight: "Weight",
            website: "Website",
            description: "Description",
            add: "Add",
            kg: "KG"
        },
        titles: {
            myPage: {
                parcels: "Parcels",
                addresses: "Addresses",
                parcelTypes: {
                    all: "All",
                    received: "warehouse",
                    sent: "Sent",
                    arrived: "Arrived",
                    takenOut: "Taken out"
                },
                keyword: "Enter keyword",
                pay: "Payment",
                flightId: "Flight ID",
                card: "With card",
                bonus: "With bonus",
                balanse: "With balance",
                tracking: "Tracking",
                price: "Price",
                status: "Status",
                payed: "Payed",
                declared: "Declared",
                country: "Country",
                weight: "Weight",
                debt: "Debt"
            }
        },
        viewParcel: {
            status: "Status",
            tracking: "Tracking",
            receiver: "Receiver",
            price: "Price",
            parcelId: "Parcel ID",
            isPayed: "Is payed",
            flightId: "Flight ID",
            description: "Description",
            currency: "Currency",
            website: "Website"
        },
        servicesPages: {
            deliveryPrices: {
                usa: "America - Parcels delivered from Monday to Wednesday are sent on Thursdays, parcels from Thursday to Sunday are sent on Mondays",
                poland: "The package that arrived from Poland before 2 o'clock on Thursday is sent out on Friday. Estimated arrival time from 5 to 15 days",
                greece: "Transportation from greece takes 7 days",
                turkey: "From Turkey we have one flights per week, every Tuesday"
            },
            onlineParcels: {
                title: "Online parcels",
                subTitle: "How service works?",
                description: "When you register on our site, you are given personal postal addresses in four countries: America, Turkey, Poland and Greece. These addresses are used to indicate when ordering products in online stores."
            },
            delivery: {
                title: "Delivery services",
                courierService: "Courier service - delivery of the parcel to the address, operates throughout the territory controlled by Georgia.",
                title2: "How to request courier service?",
                title3: "In the case of an online parcel:",
                Description3: "in order for the courier to deliver the parcel to you, you must register a courier service request for a specific parcel. The courier service is marked both during the declaration of the parcel and after the declaration in the parcel control menu.",
                title4: "Several parcels per 1 person:",
                description4: "If 2 or more parcels arrive in the name of one person and you want the courier to deliver all the parcels, you will pay for the courier service of only 1 parcel and the courier will deliver the other parcels for free. However, it is mandatory to mark the courier service on all parcels. To do this, you need to mark the courier service on all the desired packages in the list of outgoing parcels in your room or in the list of incoming parcels.",
                title5: "Damaged packaging:",
                description5: "The customer must check the packaging of the parcel in the presence of the courier. In case of any kind of external damage, file a claim with him.",
                weightAndPrices: [
                    "Courier service throughout Tbilisi is paid and amounts to 6 GEL for up to 10 kilograms; 10-20 kg 15 GEL",
                    "Courier service in the territory of Georgia (except Tbilisi) is 10 GEL for up to 10 kilograms. 10-20 kg - 20 GEL",
                    "In case of more than 20 kilograms, please contact us to clarify the tariff"
                ]
            },
            prices: [
                "All parcels from the USA, Poland and Greece are charged according to their physical weight (except for the bumper). 1 kg fee from America is equivalent to 8 USD in GEL, 1 kg fee is 6,5 EUR from Poland, 4 USD from Turkey, and 3 EUR is equivalent to GEL from Greece",
                "The weight of all parcels is rounded to the nearest 100 grams.",
                "To clarify the conditions of transportation of non-standard / oversized cargo and/or parcels, please contact us in advance",
                "Courier service throughout Tbilisi is paid and amounts to 6 GEL for up to 10 kilograms; 10-20 kg 15 GEL",
                "Courier service in the territory of Georgia (except Tbilisi) is 10 GEL for up to 10 kilograms. 10-20 kg - 20 GEL",
                "In case of more than 20 kilograms, please contact us to clarify the tariff"
            ],
            bonuses: {
                title: "Bonus points",
                subtitle: "Accumulate bonus points",
                description: "Bonus points are accrued to all registered customers who pay the cost of parcel transportation by any means. Up to 5% of the paid amount is returned as bonus points. " +
                    "With bonus points it is possible to pay the cost of transporting any parcel - 1 bonus point is equal to 1 GEL. " +
                    "In order to pay the transportation fee, it is necessary that the accumulated bonus points fully cover the amount required for the transportation of the parcel."
            },
            prohibitedItems: {
                title: "List of prohibited items:",
                usa: "China - Liquids, Magnet, Gun, Gun Parts, Target, Live Flowers, Drone Element. Also products with explosive and flammable packaging. Among them: spray, pulverizer, deodorizer, aerosol, perfume.",
                subtitle: "According to the legislation of Georgia, it is prohibited to send the following items by mail:",
                prohibited: ["Real money, coins or banknotes", "Bank and credit cards and other securities", "Food products", "Explosives", "Soil and seedlings with soil",
                    "pornographic products", "live animals", "any kind of drugs, products containing narcotic substances", "plants containing narcotic or psychotropic substances or their seeds",
                    "Psychotropic substances", "Medicines (more than 10 packs)", "Plant seeds (more than 25 grams)",
                    "In addition to the products listed below, the following are prohibited from China: all kinds of liquids (lotions, ointments, perfumes, etc.), powders (eye make-up, pressed powders, etc.), plants, elements, accumulators, so-called power banks, knives, electronics. Transport of cigarettes and their liquids",
                    "The transportation of the following types of products is restricted:", 'perfume, "spray"', "food products", "paint, alcoholic beverages, etc.",
                    "Explosive and easily flammable substances or products (for example, lighters, New Year's fireworks, etc., the sending of second-hand gasoline pumps or second-hand auto parts cleaned with gasoline is also restricted from England)",
                    'pornographic products', 'animals, plants, fish, birds, rodents, etc.', 'toxic substances (including car batteries)',
                    'Firearms, crossbows, (bows), parts or accessories for firearms (including optical sights, air guns)',
                    "Products or plants or their seeds containing narcotic substances (including the so-called bio flavorings - spices)",
                    "Magnets and products containing so-called 'asbestos'", "stuffed animals, bones, fur, ivory, etc.",
                    'Real money - coins or banknotes of any country and bank plastic cards', 'Items under high pressure (eg shock absorber, airbag, etc.)',
                    'You can subscribe to our page to get information about specific items.'
                ]
            },
            usefulInformation: {
                title: "How to buy",
                content: [
                    "Register on our site www.easypost.ge", "Register on the site from which you order products", "After authorization on www.easypost.ge, you will see the address of 5 countries assigned to you on our site, from which you should copy the desired one in the appropriate fields on the online store site. ",
                     "When selecting the address, take into account which country site you want to subscribe from.", "A few days after the subscription, the courier will pick up your package from the store to our office.", "Declaration is a fairly simple procedure, you only need to fill in a few fields:",
                     "Value - the amount paid for the item", "Currency - in what currency did you pay the price of the item", "Country - which country did you subscribe from", "Site - from which website did you subscribe", "Description of the item - what did you buy",
                     "After declaring, you have to wait for the item in Georgia Information regarding arrival, delivery of parcels You will receive an SMS message."
                ],
                customsProcedure: {
                    title: "Customs procedure",
                    content: [
                        "In what cases is the parcel subject to customs clearance?",
                        "According to the legislation of Georgia, goods worth more than 300 GEL, weighing more than 30 kg or uniform goods are subject to customs clearance.",
                        "If the parcels ordered by you will be on one flight and their total value is more than 300 GEL, they will also be subject to customs clearance.",
                        "If the parcels ordered by you from different sites are on one flight and their value is more than 300 GEL, the parcels will not be subject to customs clearance.",
                        "What is the customs clearance procedure?",

                        "From May 14, 2022, an amendment was introduced to the law. In particular, the Resolution of the Government of Georgia No. 201 was changed. According to the amendment, the Government of Georgia imposes a customs service tax on goods to be cleared, along with the customs clearance amount.",
                        "For goods worth 300 to 3,000 GEL, 20 GEL will be added to the customs clearance amount;",
                        "For goods worth from 3,000 to 10,000 GEL, 100 GEL will be added to the customs clearance amount.",
                        "The said amount will be paid to the account of the Treasury of Georgia, before the declaration is printed and the customs clearance fee is paid.",
                        "All necessary procedures for customs clearance and relations with customs are carried out by the declarants of our company.", "The parcel to be cleared from customs comes to our office, we print the declaration for you and take the parcel out.",
                        "The amount of customs clearance, payment deadline and bank details of the Treasury of Georgia are specified in the declaration and you can pay it at any bank", "What documentation is required to clear the parcel?",
                        "In the event that the parcel has been subject to customs clearance, the customer must upload an invoice/purchase order to our site, specifying the recipient's name, surname, room number, the contents of the parcel and the total value.",
                        "How much is the customs clearance fee?", "The customs fee is 18% of the total cost of the products and transportation.", "What happens if the customer does not pay the customs clearance fee on time?",
                        "The customer is responsible for the customs clearance of the cargo and timely payment of VAT.", "If you do not pay the customs clearance amount within the time specified in the declaration, you will be charged an additional fee according to the legislation of Georgia.",
                        "Why is it necessary to declare the item before arrival?", "If the package is not declared at the moment of its arrival, it will be delayed at customs for 8 days.",
                        "In the event that you do not declare the parcel within the mentioned 8 days, the parcel will be automatically transferred to the state for further disposal. Note that if several parcels cross the border in the name of the customer, and only a part is undeclared, the customs will stop the declared parcels of the customer together with the undeclared parcels. 8 days After the expiration of the period, according to the law, all the parcels together are transferred to the ownership of the state."
                    ]
                }
            }
        }
    },
    Geo: {
        delivery: {
            parcels: "გზავნილი",
            address: "მისამართი",
            price: "ფასი",
            date: "თარიღი",
            status: "სტატუსი",
            title: "მიწოდების სერვისი"
        },
        mainPage: {
            services_title: "ჩვენი სერვისები",
            online_parcels: "ონლაინ გზავნილები",
            help: "როგორ ვიყიდო",
            delivery: "მიწოდების სერვისი",
            bonus: "ბონუს ქულები",
            flights: "რეისები",
            id: "რეისის ნომერი",
            status: "სტატუსი",
            country: "ქვეყანა",
            take_off: "გამოფრენა",
            arrive: "ჩამოსვლა",
            on_the_way: "გზაში",
            arrived: "ჩამოსული",
            office: "ჩვენი ოფისი",
            calculator: "კალკულატორი"
        },
        parcelsHead: {
            tracking: "თრექინგი",
            price: "ფასი",
            status: "სტატუსი",
            isPayed: "გადახდილი",
            currency: "ვალუტა",
            isDeclared: "დეკლარირება",
            debt: "დავალიანება"
        },
        officeAddresses: ["თბილისი ალეკო გობრონიძის 2", "რუსთავი-თოდრიას 15", "ქუთაისი- ჭავჭავაძის 34", "სამტრედია,სააკაძის 6", "ფოთი-ბაგრატიონის 21", "ზუგდიდი-თათარაშვილის 34", "გორი", "ქობულეთი", "ბათუმი"],
        paymentsHead: {

        },
        flightsHead: {
            number: "რეისი",
            estimatedArrivalDate: "სავარაუდო ჩამოსვლის თარიღი",
            arriveDate: "ჩამოსვლის თარიღი",
            status: "სტატუსი",
            takeOff: "გამოსვლის დრო",
            comments: "კომენტარები",
            country: "ქვეყანა"
        },
        navigation: {
            main: 'მთავარი',
            myPage: "ჩემი გვერდი",
            flights: 'რეისები',
            shopping: 'შოპინგი',
            contact: 'კონტაქტი',
            price: "ფასები",
            about: 'ჩვენს შესახებ',
            offices: 'ოფისები',
            info: "ინფორმაცია",
            services: "სერვისები",
            dashboard: "დეშბორდი",
            delivery: "მიწოდების სერვისი",
            users: "მომხმარებლები",
            parcels: "გზავნილები"
        },
        headerButtons: {
            registration: 'რეგისტრაცია',
            auth: 'ავტორიზაცია'
        },
        calculator: {
            title: "კალკულატორი",
            width: "სიგრძე (სმ)",
            height: "სიმაღლე (სმ)",
            length: "სიგრძე (სმ)",
            weight: 'კგ',
            calc: "გამოთვლა",
            description: [
                "განკუთვნილია მხოლოდ ჩინეთისთვის", "ჩინეთიდან ტრანსპორტირების საფასური ანგარიშდება როგორც ფიზიკური, ისე მოცულობითი წონით.",'რეკომენდაცია',
                "ჩინეთიდან სთხოვეთ გამყიდველს ნივთები რომელიც არ დაზიანდება , შეიფუთოს ყუთის გარეშე, რათა არ მოხდეს მოცულობით დათვლა.", "თუ მოცულობითი წონა ფიზიკურ წონაზე მეტია, ის გამოითვლება მოცულობითი წონით. გამოიყენეთ კალკულატორი მგზავრობის საფასურის წინასწარ გამოსათვლელად.",
                "გაითვალისწინეთ"
            ]
        },
        registration: {
            terms: ["ვეთანხმები", "წესებს და პირობებს"],
            title: 'რეგისტრაცია',
            identification: "საიდენთიფიკაციო კოდი",
            physicalPerson: "ფიზიკური პირი",
            legalEntity: "იურიდიული პირი",
            alreadyRegistered: ["გაქვთ ექაუნთი?", "ავტორიზაცია"],
            name: "სახელი (ლათინურად)",
            surname: "გვარი (ლათინურად)",
            nameGeo: "სახელი (ქართულად)",
            surnameGeo: "გვარი (ქართულად)",
            email: "ელ-ფოსტა",
            personal: 'პირადი ნომერი',
            phone: 'ტელ.ნომერი',
            add: 'რეგისტრაცია',
            dateOfBirth: "დაბადების თარიღი",
            address: "მისამართი",
            officeAddress: "ოფისის მისამართი",
            currentAddress: "ფაქტიური მისამართი",
            password: "პაროლი",
            repeatPass: "გაიმეორეთ პაროლი",
            city: "ქალაქი",
            save: "შენახვა",
            edit: "პროფილის რედაქტირება"
        },
        auth: {
            resetPassword: "პაროლის აღდგენა",
            title: "ავტორიზაცია",
            code: "შეიყვანეთ კოდი",
            resetPassTitle: "პაროლის აღდგენა",
            passwordChanged: "პაროლი აღდგენილია",
            email: "ელ. ფოსტა",
            newPassword: "Enter new password",
            repeatPassword: "Repeat new password",
            password: "პაროლი",
            submit: "შესვლა",
            incorrectEmail: 'ელ. ფოსტა არ მოიძებნა',
            incorrectPassword: 'მომხმარებლის პაროლი არასწორია',
            registration: "რეგისტრაცია",
            resetPass: "პაროლის აღდგენა",
            roomNumber: "ოთახის ნომერი",
            balance: "ბალანსი",
            debt: "დავალიანება",
            deliveryDebt: "მიწოდების დავალიანება",
            editProfile: "პროფილის რედაქტირება",
            logout: "გასვლა"
        },
        cities: {
            usa: "აშშ",
            poland: "პოლონეთი",
            greece: "საბერძნეთი",
            turkey: "თურქეთი",
            china: "ჩინეთი"
        },
        addresses: {
            name: "სახელი",
            addressTitle: "Adres",
            surname: "გვარი",
            weight: "წონა",
            receiveAddress: "გზავნილის მიღების მისამართი",
            streetTur: "Mahalle",
            cityTur: "IL",
            nameTur: "AD",
            surnameTur: "SOYAD",
            address: "მისამართი",
            flatNumber: "ბინის ნომერი",
            addressOne: "მისამართი 1",
            addressTwo: "მისამართი 2",
            city: "ქალაქი",
            district: "İlçe",
            street: "სამეზობლო/ქუჩა",
            addressTitle2: "Adres Başlığı",
            state: "შტატი",
            country: "ქვეყანა",
            postCode: "საფოსტო ინდექსი",
            phone: "ტელ. ნომერი",
            region: "ქვეყანა"
        },
        services: {
            head: "სერვისები",
            subtitle: "ჩვენ გთავაზობთ უამრავ სერვისს თქვენი ცხოვრების გასაუმჯობესებლად",
            service: [
                {
                    title: "ონლაინ ამანათები",
                    description: "გააგზავნეთ თქვენ მიერ შეძენილი ნივთი ფოსტას იმ ქვეყნის საწყობში, საიდანაც გსურთ"
                },
                {
                    title: "გამოწერაში დახმარება",
                    description: "გააგზავნეთ თქვენ მიერ შეძენილი ნივთი ფოსტას იმ ქვეყნის საწყობში, საიდანაც გსურთ"
                },
                {
                    title: "საკურიერო მომსახურება",
                    description: "გააგზავნეთ თქვენ მიერ შეძენილი ნივთი ფოსტას იმ ქვეყნის საწყობში, საიდანაც გსურთ"
                },
                {
                    title: "ბონუს ქულები",
                    description: "გააგზავნეთ თქვენ მიერ შეძენილი ნივთი ფოსტას იმ ქვეყნის საწყობში, საიდანაც გსურთ"
                }
            ]
        },
        servicesDropdown: {
            onlineParcels: "ონლაინ ამანათები",
            help: "გამოწერაში დახმარება",
            insurance: "დაზღვევა",
            delivery: "საკურიერო მომსახურება",
            bonus: "ბონუს ქულები",
            prices: "ფასები"
        },
        info: {
            howToFollow: "როგორ გამოვიწერო",
            terms: "საბაჟო წესები",
            restrictedItems: "აკრძალული ნივთები",
            frequentlyQuestions: "ხშირად დასმული კითხვები",
            transportation: "ტრანსპორტირების ვადები",
            aboutUs: "ჩვენს შესახებ",
            news: "სიახლე",

        },
        contacts: {
            title: ["დაგვიკავშირდით, ჩვენ რაც", "შეიძლება მალე გიპასუხებთ!"],
            name: "სახელი",
            surname: "გვარი",
            phone: "ტელ. ნომერი",
            message: "შეტყობინება",
            contactName: "კონტაქტები",
            location: "ლოკაცია",
            mail: "ელ. ფოსტა",
            address: "ალეკო გობრონიძის 2",
            social: "სოციალური ქსელები",
            send: "გაგზავნა"
        },
        authed: {
            balance: "ბალანსი",
            debt: "დავალიანება",
            edit: "პროფილის რედაქტირება",
            bonus: "ბონუს ქულები",
            logout: "გასვლა"
        },
        addParcels: {
            receiver: "მიმღები",
            flightId: "რეისის ნომერი",
            status: "სტატუსი",
            weight: "წონა",
            userId: "მომხმარებლის ID",
            addFile: "ფაილის ატვირთვა",
            title: "გზავნილის დამატება",
            tracking: "თრექინგი",
            price: "ფასი",
            delivery: "ადგილზე მიტანა",
            currency: "ვალუტა",
            website: "საიტი",
            kg: "კგ",
            description: "აღწერა",
            add: "დამატება"
        },
        titles: {
            myPage: {
                parcels: "გზავნილები",
                addresses: "მისამართები",
                flightId: "რეისი",
                parcelTypes: {
                    all: "ყველა ამანათი",
                    received: "საწყობში",
                    sent: "გაგზავნილი",
                    arrived: "ჩამოსული",
                    takenOut: "გატანილი"
                },
                keyword: "ჩაწერეთ საძიებო სიტყვა",
                pay: "გადახდა",
                card: "ბარათით",
                bonus: "Bონუსით",
                balanse: "ბალანსით",
                tracking: "თრექინგი",
                price: "ფასი",
                status: "სტატუსი",
                payed: "გადახდილი",
                declared: "დეკლარირებული",
                country: "ქვეყანა",
                weight: "წონა",
                debt: "დავალიანება"
            }
        },
        viewParcel: {
            status: "სტატუსი",
            tracking: "თრექინგი",
            receiver: "მიმღები",
            price: "ფასი",
            parcelId: "გზავნილის ID",
            isPayed: "გადახდილი",
            flightId: "რეისის ID",
            description: "აღწერა",
            currency: "ვალუტა",
            website: "საიტი"
        },
        servicesPages: {
            deliveryPrices: {
                usa: "აშშ-დან ორშაბათიდან ოთხშაბათის ჩათვლით მიტანილი ამანათები იგზავნება ხუთშბათობით, ხუთშაბათიდან კვირის ჩათვლით მისული ამანათები - ორშაბათობით.ტრანსპორტირების ვადა არის 7-10 დღე",
                poland: "საბერძნეთიდან ტრანსპორტირებას ჭირდება 7-8 დღე , რეისი გამოდის ყოველ კვირა დღეს.",
                greece: "თურქეთიდან რეისი გამოდის ოთხშაბათს და კვირას.ტრანსპორტირების ვადა არის 4-5 დღე.",
                turkey: "ჩინეთიდან რეისი გამოდის ორშაბათს ,ოთხშაბათს და პარასკევს, ტრანსპორტირების ვადა შეადგენს 7-9 დღე."
            },
            onlineParcels: {
                title: "ონლაინ ამანათები",
                subTitle: "როგორ მუშაობს სერვისი?",
                description: "ჩვენს საიტზე რეგისტრაციისას გეძლევათ პირადი საფოსტო მისამართები ოთხ ქვეყანაში: ამერიკაში, თურქეთი, პოლონეთში და საბერძნეთში ეს მისამართები გამოიყენება ონლაინ მაღაზიებში პროდუქციის გამოწერის დროს მისათითებლად."
            },
            delivery: {
                title: "საკურიერო მომსახურება",
                courierService: "საკურიერო მომსახურება - ამანათის მისამართზე მიტანა, მოქმედებს საქართველოს მიერ კონტროლირებად მთელს ტერიტორიაზე.",
                title2: "როგორ მოვითხოვოთ საკურიერო მომსახურება?",
                title3: "ონლაინ ამანათის შემთხვევაში:",
                Description3: "იმისთვის, რომ კურიერმა მოგიტანოთ ამანათი, კონკრეტულ ამანათზე უნდა დააფიქსიროთ საკურიერო მომსახურების მოთხოვნა. საკურიერო მომსახურების მონიშვნა ხდება, როგორც ამანათის დეკლარირებისას, ისე დეკლარირების შემდეგ ამანათის კონტროლის მენიუში.",
                title4: "რამდენიმე ამანათი 1 პიროვნებაზე: ",
                description4: "იმ შემთხვევაში, თუ ერთი პიროვნების სახელზე ჩამოვა 2 ან მეტი ამანათი და გინდათ, რომ ყველა ამანათი მოგიტანოთ კურიერმა გადაიხდით მხოლოდ 1 ამანათის საკურიერო მომსახურების თანხას დანარჩენ ამანათებს კურიერი მოგიტანთ უფასოდ. თუმცა, სავალდებულოა, ყველა ამანათზე მონიშნოთ საკურიერო მომსახურება. ამისათვის საჭიროა თქვენს ოთახში წამოსული ამანათების სიაში ან ჩამოსული ამანათების სიაში ყველა სასურველ ამანათზე მონიშნოთ საკურიერო მომსახურება.",
                title5: "დაზიანებული შეფუთვა:",
                description5: "მომხმარებელმა კურიერის თანდასწრებით უნდა შეამოწმოს ამანათის შეფუთვა. რაიმე სახის გარეგანი დაზიანების შემთხვევაში კი მასთან დააფიქსიროს პრეტენზია.",
                weightAndPrices: [
                    "თბილისის მასშტაბით საკურიერო მომსახურება ფასიანია და შეადგენს 10 კილოგრამამდე 6 ლარს. ფონიჭალა - ლილო 8 ლარი. დიდი ზომის ამანათები 10 ლარი. ",
                    "საქართველოს ტერიტორიაზე (თბილისის გარდა) საკურიერო მომსახურება შეადგენს 10 კილოგრამამდე 10 ლარს. 10-20კგ - 20 ლარი",
                    "20 კილოგრამზე მეტის შემთხვევაში ტარიფის დასაზუსტებლად გთხოვთ დაგვიკავშირდეთ"
                ]
            },
            prices: [
                "აშშ-დან 1 კგ ფასი არის 8 დოლარი . ამანათის ტრანსპორტირების საფასური ანგარიშდება მისი ფიზიკური წონის მიხედვით (ა/მ ბამპერის გარდა).",
                "საბერძნეთიდან 1 კგ ფასი არის 3 ევრო. ამანათის ტრანსპორტირების საფასური ანგარიშდება მისი ფიზიკური წონის მიხედვით.",
                "თურქეთიუდან 1 კგ ფასი არის 2 დოლარი.ამანათის ტრანსპორტირების საფასური ანგარიშდება მისი ფიზიკური წონის მიხედვით.",
                "არასტანდარტული / არაგაბარიტული ტვირთის ან/და ამანთების ტრანსპორტირების პირობების დასაზუსტებლად, გთხოვთ წინასწარ დაგვიკავშირდეთ.",
                "ყველა ამანათის წონა მრგვალდება 100 გრამის სიზუსტით.",
                "არასტანდარტული / არაგაბარიტული ტვირთის ან/და ამანთების ტრანსპორტირების პირობების დასაზუსტებლად, გთხოვთ წინასწარ დაგვიკავშირდეთ."
            ],
            bonuses: {
                title: "ბონუს ქულები",
                subtitle: "ბონუს ქულების დაგროვება",
                description: "ბონუს ქულები ერიცხება ყველა დარეგისტრირებულ მომხმარებელს, რომელიც ნებისმიერი საშუალებით გადაიხდის ამანათის ტრანსპორტირების ღირებულებას. გადახდილი თანხის 5%-მდე უკან ბონუს ქულებად ბრუნდება. ბონუს ქულებით შესაძლებელია ნებისმიერი ამანათის ტრანსპორტირების ღირებულების გადახდა - 1 ბონუს ქულა უდრის 1 ლარის. ტრანსპორტირების საფასურის გადასახდელად აუცილებელია დაგროვილი ბონუს ქულები სრულად ფარავდეს ამანათის ტრანსპორტირებისთვის საჭირო თანხას."
            },
            prohibitedItems: {
                title: "აკრძალული ნივთების სია:",
                usa: "ჩინეთი - სითხეები, მაგნიტი, იარაღი, იარაღის ნაწილები, სამიზნე, ცოცხალი ყვავილები, დრონის ელემენტი. ასევე ფეთქებადსაშიში და აალებადი შეფუთვის მქონე პროდუქცია. მათ შორის: სპრეი, პულვერიზატორი, დეზადორი, აეროზოლი, სუნამო.",
                subtitle: "საქართველოს კანონმდებლობით აკრძალულია საფოსტო გზავნილით შემდეგი სახის ნივთების გამოგზავნა:",
                prohibited: ["რეალური ფული, მონეტები ან ბანკნოტები", "საბანკო და საკრედიტო ბარათები და სხვა ფასიანი ქაღალდები", "საკვები პროდუქცია", "ფეთქებადი ნივთიერებები", "მიწა და ნერგები მიწით",
                    "პორნოგრაფიული სახის პროდუქცია", "ცოცხალი ცხოველები", "ნებისმიერი სახის ნარკოტიკი, ნარკოტიკული ნივთიერებების შემცველი პროდუქცია", "ნარკოტიკული ან ფსიქოტროპული ნივთიერებების შემცველი მცენარეები ან მათი თესლი",
                    "ფსიქოტროპული ნივთიერებები", "წამლები (10 შეკვრაზე მეტი)", "მცენარეების თესლები (25 გრამზე მეტი)","ჩინეთიდან ქვემოთ ჩამოთვლილი პროდუქციის გარდა აკრძალულია: ყველა სახის სითხეების (ლოსიონები, მალამოები, პარფიუმერია და ა.შ) ფხვნილების (თვალის მაკიაჟი, დაპრესილი ფხვნილები და ა.შ) მცენარეების, ელემენტების, აკუმულატორების, ე.წ. ფოვერბანკების, დანების, ელ. სიგარეტების და მისი სითხეების  ტრანსპორტირება",
                    "შეზღუდულია შემდეგი სახის პროდუქციის ტრანსპორტირება:", 'სუნამო, "სფრეი"', 'საკვები პროდუქტები', 'საღებავი, ალკოჰოლური სასმელები და ა.შ.',
                    'ფეთქებადი და ადვილად აალებადი ნივთიერებები ან პროდუქცია (მაგ. სანთებელა, საახალწლო მაშხალა  და ა.შ. ასევე ინგლისიდან შეზღუდულია მეორადი ბენზონასოსების, ან ბეზინით გაწმენდილი მეორადი ავტონაწილების გამოგზავნა)',
                    'პორნოგრაფიული სახის პროდუქცია.', 'ცხოველები, მცენარეები, თევზები, ჩიტები, მღრღნელები და ა.შ.', 'ტოქსიკური ნივთიერებები (მათ შორის მანქანის აკუმულატორები)',
                    'ცეცხლსასროლი იარაღი, არბალეტები, (მშვილდები), ცეცხლსასროლი იარაღის ნაწილები ან იარაღის აქსესუარები (თუნდაც ოპტიკური სამიზნე, პნევმატური იარაღი)',
                    'ნარკოტიკული ნივთიერებების შემცველი პროდუქცია ან მცენარეები ან მათი თესლი  (მათ შორის ე.წ ბიო არომატიზატორები- სპაისები)',
                    "მაგნიტები და ე.წ. 'აზბესტი'–ს შემცველობის პროდუქცია", 'ცხოველების ფიტულები, ძვლები, ბეწვი, სპილოს ძვალი და ა.შ.',
                    'რეალური ფული - მონეტები ან ბანკნოტები ნებისმიერი ქვეყნის და საბანკო პლასტიკური ბარათები', 'მაღალი წნევის ქვეშ მყოფი ნივთები (მაგ. ამორტიზატორი, აირბეგი და ა.შ.)',
                    'კონკრეტულ ნივთებთან დაკავშირებით ინფორმაციის მისაღებად შეგიძლიათ მოიწეროთ ჩვენს გვერდზე.'
                ]
            },
            usefulInformation: {
                title: "როგორ გამოვიწერო ამანათი",
                content: [
                    "დარეგისტრდით ჩვენს საიტზე www.easypost.ge", "დარეგისტრირდით საიტზე, საიდანაც იწერთ პროდუქციას", "www.easypost.ge - ზე ავტორიზაციის გავლის შემდეგ ჩვენს საიტზე გამოჩნდებათ თქვენთვის მინიჭებული 5 ქვეყნის მისამართი, რომლიდანაც სასურველი უნდა დააკოპიროთ ონლაინ მაღაზიის საიტზე შესაბამის ველებში.",
                    "მისამართის შერჩევისას გაითვალისწინეთ, რომელი ქვეყნის საიტიდან გინდათ გამოწერა.", "გამოწერიდან რამდენიმე დღეში კურიერი მაღაზიიდან წამოიღებს თქვენს ამანათს ჩვენს ოფისში.", "დეკლარირება საკმაოდ მარტივი პროცედურაა, საჭიროა მხოლოდ რამოდენიმე ველის შევსება:",
                    "ღირებულება - ნივთში გადახდილი თანხა", "ვალუტა-რა ვალუტაშიც გადაიხადეთ ნივთის ღირებულება", "ქვეყანა-რომელი ქვეყნიდან გამოიწერეთ", "საიტი-რომელი საიტიდანაც გაქვთ ამანათი გამოწერილი", "ნივთის აღწერა - რა შეიძინეთ",
                    "დეკლარირების შემდეგ უნდა დაელოდოთ ნივთის საქართველოში ჩამოსვლას,ამანათების გაცემასთან დაკავშირებით ინფორმაციას მიიღებთ სმს-ს შეტყობინების სახით."
                ],
                customsProcedure: {
                    title: "საბაჟო წესები და პროცედურები",
                    content: [
                        "რა შემთხვევაში ექვემდებარება ამანათი განბაჟებას?",
                        "საქართველოს კანონმდებლობით განბაჟებას ექვემდებარება 300 ლარზე მეტი ღირებულების, 30კგ-ზე მეტი წონის ან ერთგვაროვანი საქონელი.",
                        "თუ თქვენს მიერ გამოწერილი ამანათები მოხვდება ერთ რეისზე და მათი ჯამური ღირებულება 300 ლარზე მეტია, ასევე დაექვემდებარება განბაჟებას.",
                        "თუ თქვენს მიერ სხვადასხვა საიტიდან გამოწერილი ამანათები მოხვდება ერთ რეისზე და მათ ღირებულება 300 ლარზე მეტია, ამანათები არ დაექვემდებარება განბაჟებას.",
                        "როგორია განბაჟების პროცედურა?",

                        "2022 წლის 14 მაისიდან კანონში შევიდა ცვლილება. კერძოდ შეიცვალა საქართველოს მთავრობის No201 დადგენილება. ცვლილების მიხედვით საქართველოს მთავრობა განბაჟების თანხის პარალელურად, აწესებს საქართველოს საბაჟოს მომსახურების გადასახადს განსაბაჟებელ საქონელზე.",
                        "300-დან 3 000 ლარამდე ღირებულების საქონელზე განსაბაჟებელ თანხას დაემატება – 20 ლარი;",
                        "3000-დან 10 000 ლარამდე ღირებულების საქონელზე კი განსაბაჟებელ თანხას დაემატება – 100 ლარი.",
                        "აღნიშნული თანხის გადახდა მოხდება საქართველოს ხაზინის ანგარიშზე, დეკლარაციის დაბეჭდვამდე და განბაჟების საფასურის გადახდამდე.",

                        "განბაჟებისთვის საჭირო ყველანაირ პროცედურას და საბაჟოსთან ურთიერთობას ახორციელებენ ჩვენი კომპანიის დეკლარანტები.", "განსაბაჟებელი ამანათი საბაჟოდან მოდის ჩვენს ოფისში, ჩვენ გიბეჭდავთ დეკლარაციას და გატანთ ამანათს.",
                        "განბაჟების თანხა, გადახდის ბოლო ვადა და საქართველოს ხაზინის საბანკო რეკვიზიტები მითითებულია დეკლარაციაში და მისი გადახდა შეგიძლიათ ნებისმიერ ბანკში", "რა დოკუმენტაცია საჭირო ამანათის განსაბაჟებლად?",
                        "იმ შემთხვევაში, თუ ამანათი დაექვემდებარა განბაჟებას, მომხმარებელმა ჩვენს საიტზე უნდა ატვირთოს ინვოისი/შესყიდვის ორდერი, სადაც ფიქსირდება მიმღების სახელი, გვარი, ოთახის ნომერი, ამანათის შიგთავსი და ჯამური ღირებულება.",
                        "რამდენია განბაჟების გადასახადი?", "საბაჟო გადასახადი შეადგენს პროდუქციის ღირებულების და ტრანსპორტირების ჯამის 18%-ს.", "რა ხდება თუ მომხმარებელი დროულად არ გადაიხდის განბაჟების საფასურს?",
                        "ტვირთის განბაჟებაზე და დღგ-ს დროულად გადახდაზე პასუხისმგებელია მომხმარებელი.", "თუ დეკლარაციაში მითითებულ ვადაში არ გადაიხდით განბაჟების თანხას, საქართველოს კანონმდებლობით დამატებით დაგერიცხებათ საურავი.",
                        "რატომ არის საჭირო ნივთის დეკლარირება ჩამოსვლამდე?", "იმ შემთხვევაში, თუ ამანათი არ იქნება დეკლარირებული მისი ჩამოსვლის მომენტში, იგი დაყოვნდება საბაჟოზე 8 დღის განმავლობაში.",
                        "იმ შემთხვევაში, თუ აღნიშნული 8 დღის განმავლობაში, თქვენს მიერ არ მოხდება ამანათის დეკლარირება, ამანათი ავტომატურად გადაეცემა სახელმწიფოს შემდგომი განკარგვისთვის. გაითვალისწინეთ, თუ მომხმარებლის სახელზე საზღვარს კვეთს რამდენიმე ამანათი, ხოლო დაუდეკლარირებელია მხოლოდ ნაწილი, საბაჟო დაუდეკლარირებელ ამანათებთან ერთად აჩერებს მომხმარებლის დადეკლარირებულ ამანათებსაც. 8 დღიანი პერიოდის გასვლის შემდეკ კი კანონის მიხედვით ყველა ამანათი ერთად გადადის სახელმწიფოს მფლობელობაში."
                    ]
                }
            }
        }
    }
}

export default languages
