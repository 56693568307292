import axios from "axios";
import { useState } from "react"
import languages from "../../data/languages";
import { useSelector } from "react-redux";
import TermsAndConditions from "../../Components/TermsAndConditions";
import { Modal } from "react-bootstrap";

const LegalEntities = ({ showAuthModal, showSignUpModal }) => {
    const [errorModal, setShowErrorModal] = useState(false);
    const [success, setSuccess] = useState(false);
    const [showTermsAndConditionsModal, setShowTermsAndConditionsModal] = useState(false);
    const [successText, setSuccessText] = useState("");
    const lang = useSelector((state) => state.language.language);
    const [formData, setFormData] = useState({
        nameEn: "",
        nameGeo: "",
        identificationCode: "",
        phone: "",
        address: "",
        email: "",
        password: "",
        repeatPassword: "",
        agree: false
    })
    const [error, setError] = useState("")

    const signUpHandler = (e) => {
        e.preventDefault();
        if (!formData.agree) {
            setShowErrorModal(true);
            setError("Please agree our terms and conditions")
        } else {
            axios.post(`/api/legal-entities`, formData)
                .then((response) => {
                    const result = response.data.message.Geo;
                    setShowErrorModal(false)
                    setSuccess(true);
                    setSuccessText(result);
                    setFormData({
                        nameEn: "",
                        nameGeo: "",
                        identificationCode: "",
                        phone: "",
                        address: "",
                        email: "",
                        password: "",
                        repeatPassword: "",
                        agree: false
                    })
                })
                .catch((err) => {
                    const result = err.response.data.message.En;
                    setError(result);
                    setShowErrorModal(true)
                    setSuccess(false);
                })
        }
    }

    return (
        <>
            <form className="container" onSubmit={signUpHandler}>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label className="mt-3 mb-1 color-white">{languages[lang].registration.name}</label>
                            <input type="text" className="form-control" value={formData.nameEn} onChange={(e) => {
                                if (/^[a-zA-Z\s]*$/.test(e.target.value) || e.target.value === '') {
                                    setFormData((prev) => ({ ...prev, nameEn: e.target.value }))
                                }
                            }} placeholder={languages[lang].registration.name} required />
                        </div>
                        <div className="form-group">
                            <label className="mt-3 mb-1 color-white">{languages[lang].registration.nameGeo}</label>
                            <input type="text" className="form-control" value={formData.nameGeo} onChange={(e) => {
                                if (/^[\u10A0-\u10FF\s]*$/.test(e.target.value) || e.target.value === '') {
                                    setFormData((prev) => ({ ...prev, nameGeo: e.target.value }))
                                }
                            }} placeholder={languages[lang].registration.nameGeo} required />
                        </div>
                        <div className="form-group">
                            <label className="mt-3 mb-1 color-white">{languages[lang].registration.identification}</label>
                            <input type="text" className="form-control" value={formData.identificationCode} onChange={(e) => {
                                if (/^[0-9]*$/.test(e.target.value) || e.target.value === '') {
                                    setFormData((prev) => ({ ...prev, identificationCode: e.target.value }))
                                }
                            }} placeholder={languages[lang].registration.identification} required />
                        </div>
                        <div className="form-group">
                            <label className="mt-3 mb-1 color-white">{languages[lang].registration.phone}</label>
                            <input type="text" className="form-control" value={formData.phone} onChange={(e) => {
                                if (/^[0-9]*$/.test(e.target.value) || e.target.value === '') {
                                    setFormData((prev) => ({ ...prev, phone: e.target.value }))
                                }
                            }} placeholder={languages[lang].registration.phone} required />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label className="mt-3 mb-1 color-white">{languages[lang].registration.address}</label>
                            <input type="text" className="form-control" value={formData.address} onChange={(e) => setFormData((prev) => ({ ...prev, address: e.target.value }))} placeholder={languages[lang].registration.address} required />
                        </div>
                        <div className="form-group">
                            <label className="mt-3 mb-1 color-white">{languages[lang].registration.email}</label>
                            <input type="text" className="form-control" value={formData.email} onChange={(e) => setFormData((prev) => ({ ...prev, email: e.target.value }))} placeholder={languages[lang].registration.email} required />
                        </div>
                        <div className="form-group">
                            <label className="mt-3 mb-1 color-white">{languages[lang].registration.password}</label>
                            <input type="password" className="form-control" value={formData.password} onChange={(e) => setFormData((prev) => ({ ...prev, password: e.target.value }))} placeholder={languages[lang].registration.password} required />
                        </div>
                        <div className="form-group">
                            <label className="mt-3 mb-1 color-white">{languages[lang].registration.repeatPass}</label>
                            <input type="password" className="form-control" value={formData.repeatPassword} onChange={(e) => setFormData((prev) => ({ ...prev, repeatPassword: e.target.value }))} placeholder={languages[lang].registration.repeatPass} required />
                        </div>
                    </div>
                    <div className="terms-and-conditions-agreement">
                        <input style={{ marginTop: "0px" }} id="agree" onChange={(e) => setFormData((prev) => ({ ...prev, agree: e.target.checked }))} value={formData.agree} className="form-check-input" type="checkbox" />
                        <label htmlFor="agree">{languages[lang].registration.terms[0]}</label>
                        <span onClick={() => setShowTermsAndConditionsModal(true)} style={{color: "blue", textDecoration: "underline", cursor: "pointer"}}>{languages[lang].registration.terms[1]}</span>
                    </div>
                    <div className="mt-3">
                        {errorModal && <div className="alert alert-danger">{error}</div>}
                        {success && <div className="alert alert-success">{successText}</div>}
                    </div>
                    <div className="col-md-12">
                        <button className="sign-up-button" type="submit">{languages[lang].registration.title}</button>
                    </div>
                </div>
            </form>
            <Modal show={showTermsAndConditionsModal} onHide={() => setShowTermsAndConditionsModal(false)} size="xl" centered >
                <div className="terms-and-conditions-box">
                    <TermsAndConditions />
                </div>
            </Modal>
        </>
    )
}

export default LegalEntities