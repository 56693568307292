import { Link, Route, Switch } from 'react-router-dom/cjs/react-router-dom.min'
import './Services.css'
import { useEffect, useState } from 'react'
import OnlineParcels from './OnlineParcels';
import Delivery from './Delivery';
import Bonus from './Bonus';
import ServicesPrices from './ServicesPrices';
import { useSelector } from "react-redux";
import languages from '../../data/languages';

const Services = ({ setIsAdmin, setPageName }) => {
    const lang = useSelector((state) => state.language.language);
    const [activeTab, setActiveTab] = useState("");
    useEffect(() => {
        setIsAdmin(false);
        setPageName("");
    }, [setIsAdmin])
    return (
        <>
            <div className="services-items-slider sign-up-role-switcher">
                <Link to="/services/prices"><button className={activeTab === "prices" ? "active-tab" : ""}>{languages[lang].navigation.price}</button></Link>
                <Link to="/services/online-parcels"><button className={activeTab === "online-parcels" ? "active-tab" : ""}>{languages[lang].servicesDropdown.onlineParcels}</button></Link>
                <Link to="/services/delivery"><button className={activeTab === "delivery" ? "active-tab" : ""}>{languages[lang].servicesDropdown.delivery}</button></Link>
            </div>
            <Switch>
                <Route path="/services/online-parcels"><OnlineParcels activePageSetter={setActiveTab} /></Route>
                <Route path="/services/delivery"><Delivery activePageSetter={setActiveTab} /></Route>
                <Route path="/services/bonus-points"><Bonus activePageSetter={setActiveTab} /></Route>
                <Route path="/services/prices"><ServicesPrices activePageSetter={setActiveTab} /></Route>
            </Switch>
        </>

    )
}

export default Services